import React, {useEffect, useState} from 'react';
import { Row, Col, Tab, Nav, Button } from "react-bootstrap";
import LineChart from '../charts/LineChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { OwletController } from '../../../controllers/OwletController';
import OwletMap from "./owlet_map";
import { ExedraAPIController } from '../../../controllers/ExedraAPIController';

import moment from 'moment';

function OwletDetails(props) {


    const OwletControllerInst = OwletController.getInstance();
    const ExedraAPIControllerInst = ExedraAPIController.getInstance();

    const [consumptionData, setConsumptionData] = useState([])

    const [type, setType] = useState(30);
    const [loading, setLoading] = useState(false);
    const [lamps, setLamps] = useState([]);
    const [failures, setFailures] = useState([])
    const [mapType, setMapType] = useState('broken');
    const [loadingData, setLoadingData] = useState(false);

    let to = moment().format("YYYY-MM-DD");
    let from = moment().subtract(type, 'days').format("YYYY-MM-DD");   

    //Get lamps on load
    useEffect(() => {
        setLoadingData(true);
        if(mapType === "broken") {
            getFailures()
        }else if(mapType === "lostnode") {
            getFailures()
        }else {
            getFailures()
        }

    }, [mapType, type]) // eslint-disable-line


    //Get statistics on load, and every type change
    useEffect(() => {
        setLoading(true);

        // get consumption data
        const today = new Date().toISOString()
        const startDay = moment().subtract(type,'d').toISOString()
        ExedraAPIControllerInst.getConsumption(type, "Day").then((response) => {
            if (response.statusCode === 200) {
                setLoading(false);
                setConsumptionData(response.data)
            }
        })
    }, [type]); // eslint-disable-line

    function setupLamps(lamps) {
        let result = [];
        lamps.forEach(lamp => {
            let temp = {
                device: {}
            };
            temp.device["id"] = lamp.id
            temp.device["geometry"] = {type:'Point', coordinates:[lamp.info.coords.coordinates[0], lamp.info.coords.coordinates[1]]}
            temp.device["street"] = lamp.info.address ? lamp.info.address.street_address : ""
            temp.device["type"] = lamp.info.model

            result.push(temp)
        });
        setLamps(result);

    }

    function getFailures() {
        ExedraAPIControllerInst.getFailures().then(response => {
            if(response.data.length) {
                setupLamps(response.data)
            }
            else {
                setupLamps([])
            }
            setLoadingData(false);
        })
    }

    function getTotal() {
        const sum = consumptionData.reduce((accumulator, data) => {
            return accumulator + data.activeEnergy;
          }, 0);
          return sum.toFixed(2)
        
    }

    return(
        <Row>
            <Col lg={8} className="owlet-details-left">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row className="owlet-details-chart-container">
                        <p style={{paddingLeft:'20px'}}>{`Teljes fogyasztás: ${getTotal()} kWh`}</p>
                        <Col sm={10} className="tab-content-wrapper">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <LineChart step={3000} labels={consumptionData.map((data) => moment(data.lastDate).format("YYYY.MM.DD."))} data={consumptionData.map((data) => data.activeEnergy)} hide={3} type="owlet" title='Fogyasztás' sum={`Teljes fogyasztás: ${getTotal()} kWh`}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <LineChart step={3000} labels={consumptionData.map((data) => moment(data.lastDate).format("YYYY.MM.DD."))} data={consumptionData.map((data) => data.activeEnergy)} hide={3} type="owlet" title='Fogyasztás' sum={`Teljes fogyasztás: ${getTotal()} kWh`}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                        <Col sm={2} className="tab-buttons-wrapper">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" onClick={() => setType(30)} disabled={loading}>30 nap</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" onClick={() => setType(7)} disabled={loading}>7 nap</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                    <Row className="owlet-details-notifications-container">
                        <Col lg={12}>
                            <div className="owlet-details-notifications">
                                <p className="title">Riasztások</p>
                                <div>
                                    <p className="subtitle"><FontAwesomeIcon size="2x" color="#df3030" icon={['fas', 'bell']} />Nem igazolt</p>
                                    <div className="data-container">
                                        <p className="text">Jelenleg nincsenek nem igazolt riasztások</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="subtitle"><FontAwesomeIcon size="2x" color="#df3030" icon={['fas', 'bell']} />Igazolt</p>
                                    <div className="data-container">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Dátum</th>
                                                    <th>ID</th>
                                                    <th>Probléma</th>
                                                    <th>Megtekintés</th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <p className="text">Jelenleg nincsenek igazolt riasztások</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="subtitle"><FontAwesomeIcon size="2x" color="#df3030" icon={['fas', 'bell']} />Riasztási előzmények</p>
                                    <div className="data-container">
                                        <p className="text">Jelenleg nincsenek riasztási előzmények</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </Col>
            <Col lg={4} className="owlet-details-right">
                <Row>
                    <Col>
                        <div className="title"><FontAwesomeIcon size="2x" color="#212529" icon={['fas', 'wrench']} /> Anomáliák</div>
                    </Col>
                </Row>
                <Row>
                    {/* <Col lg={12} className="owlet-map-buttons">
                        <Button onClick={() => setMapType("broken")} className={mapType === "broken" ? 'active' : ''} disabled={loadingData}>Lámpahiba</Button>
                        <Button onClick={() => setMapType("lostnode")} className={mapType === "lostnode" ? 'active' : ''} disabled={loadingData}>Nincs kapcsolat</Button>
                        <Button onClick={() => setMapType("highpower")} className={mapType === "highpower" ? 'active' : ''} disabled={loadingData}>Magas fogyasztás</Button>
                        {loadingData && <div className="loader-container-owlet">
                            <div className="loader" style={{margin:"auto"}}></div>
                        </div>}
                    </Col> */}
                    <Col lg={12} className="owlet-map">
                        {props.active && <OwletMap lamps={lamps} />}
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Button className="owlet-button" href="https://hu-bekescsaba.owlet-iot.com/" >Kezelés az <img alt="owlet" src="/logos/owlet_white.png" /> felületén</Button>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="owlet-logo">
                        <div className="logo-box">
                            <img alt="owlet" src="/logos/owlet_gray.png" />
                            <p>Powered by</p>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OwletDetails));
