import React from 'react';
import {useState} from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import Leaflet from 'leaflet';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../assets/images/manageyourcity.svg';
import { ReactComponent as UmsLogo } from '../assets/images/ums.svg';
import { ReactComponent as UtuLogo } from '../assets/images/utu_dark.svg';
import Cookies from 'universal-cookie';
import sha256 from 'js-sha256';
import {Row, Col, Modal, Button, Form} from "react-bootstrap";
import { history } from '../state/store';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Config from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

Leaflet.Icon.Default.imagePath = '../../node_modules/leaflet';

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('../assets/images/bekescsaba_marker.svg'),
    iconUrl: require('../assets/images/bekescsaba_marker.svg'),
    shadowUrl: null
});

/**
 * Login Component
 * 
 * Basic Functionality :
 *  - Skeleton for Login Component
 * **/
const Login = ({position, center, handleLogin, warning, loading}) => {
        const { t } = useTranslation();
        const [show, setShow] = useState(false);
        const [recoveredPass, setRecoveredPass] = useState(false);
        const [message, setMessage] = useState("");
        const [showPassword, setShowPaessword] = useState(false);

        //ForgotPWModal
        const [recoverPasswordShow, setRecoverPasswordShow] = useState(false)
        const [useremail, setUseremail] = useState("")

        const cookies = new Cookies();
        const cusername = cookies.get("username");
        const cpassword = cookies.get("password");

        const recaptchaRef = React.createRef();

        const [auth, setAuth] = useState({
            username: cusername !== undefined ? cusername : null, 
            password: cpassword !== undefined ? cpassword : null, 
            remember:false
        });


        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        const recoverPasswordShowHandleShow = () => setRecoverPasswordShow(true);
        const recoverPasswordShowHandleClose = () => {
            setRecoverPasswordShow(false);
            setMessage("")
            setUseremail(null)
        }

        const handlePassInfoClose = () => setRecoveredPass(false);

        function logout() {
            cookies.remove("username");
            cookies.remove("password");
            history.push("/")
        }

        function recoverPassword() {
            if(useremail !== null && useremail !== "") {
                axios.post('/api/test', {
                    username: auth.username,
                })
                .then(function (response) {
                    setMessage("A jelszóváltoztatási kérést kiküldtük az adminisztrátornak. A további teendőket kérem várja e-mailben : " + auth.username)
                })
                .catch(function (error) {
                    setMessage("A jelszóváltoztatás jelenleg nem üzemel. Kérem keresse fel az Adminisztrátort a következő e-mail cimen : admin@tesztadmin.com")
                });
            }else {
                setMessage("Kérem adja meg a felhasználónevét.")

            }
        }

        function onChange2(value) {
            if(value) {
                handleLogin(auth);
            }
        }

        return (
            <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Rólunk</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>Ödvözöljük az oldalon.</p>
                </Modal.Body>                
            </Modal>
            <Modal size="lg" show={recoveredPass} onHide={handlePassInfoClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Jelszóváltoztatás</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>{message}</p>
                </Modal.Body>           
            </Modal>
            <div className="login-page">
                <div className="map-border">
                    <div className="content"></div>
                </div>
                <div className="map-overlay">
                    <div className="content"></div>
                </div>
                <div className="map">
                    <Map center={center} zoom="13">
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={position}>
                        </Marker>
                    </Map>
                </div>
                <div id="login">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-8 text-center">
                                <div className="logo text-right">
                                    <Logo />
                                    <div className="place text-right">Békéscsaba</div>
                                </div>
                                <div className="card text-left">
                                    <div className="card-body" style={cookies.get("username") !== undefined ? {paddingTop:10} : {}}>
                                    {cusername !== undefined && cpassword !== undefined && !sessionStorage.username ?
                                    <div style={{paddingBotton:10}}>
                                    <Row>
                                        <Col>
                                            <p style={{textAlign:"center"}}>{cookies.get("username")}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <form onSubmit={(evt) => {
                                                evt.preventDefault();
                                                handleLogin(auth)
                                                }}>
                                                <input type="submit" className="btn btn-primary btn-block" value={t('Login')} />
                                            </form>
                                        </Col>
                                        <Col>
                                            <input type="submit" className="btn btn-primary btn-block" value={t('Kijelentkezés')} onClick={logout}/> 
                                        </Col>
                                    </Row>
                                    </div>
                                    : 
                                    <>
                                        <form onSubmit={(evt) => {
                                            evt.preventDefault();
                                            recaptchaRef.current.execute()
                                        }}>
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                size="invisible"
                                                sitekey={Config.reCAPTCHA.public}
                                                onChange={onChange2}
                                            />
                                            <div className="form-group">
                                                <input type="text" id="username" name="username" className="form-control" placeholder={t('Username')} onChange={ (evt) =>
                                                    setAuth({...auth, username:evt.target.value})
                                                    }/>
                                            </div>
                                            <div className="form-group">
                                                <input type={showPassword ? "text" : "password"} id="password" name="password" className="form-control" placeholder={'Jelszó'}  onChange={ (evt) =>
                                                    setAuth({...auth, password:sha256(evt.target.value)})
                                                    }/>
                                                <FontAwesomeIcon title="Jelszó megjelnítése" role="button" onClick={() => setShowPaessword(!showPassword)} className="show-pass" size="1x" icon={['fas', showPassword ? 'eye-slash' : 'eye'] }/>
                                            </div>
                                            {warning && <span className="warning">Felhsználónév vagy jelszó hibás!</span>}
                                            <div className="form-group form-row">
                                                
                                                <div className="lost text-left col">
                                                    <i role="button" onClick={recoverPasswordShowHandleShow}>{t('Lost Password?')}</i>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary btn-block" >{loading ? <div className="login-lds-ellipsis"><div></div><div></div><div></div><div></div></div> : 'Belépés'} </button>
                                            </div>
                                        </form>
                                    
                                        <div className="card-bottom text-center">
                                            <a  href="#more-info" className="more-info-button" onClick={handleShow}>{t('More info')}</a>
                                        </div>
                                        <br />
                                        <div className="card-bottom text-center">
                                                <span style={{fontSize: '9px'}}>Version: {Config.version.number}</span>
                                        </div>
                                        <div className="card-bottom text-center">
                                            <span style={{fontSize: '9px'}}>Copyright © 2021 All rights reserved</span>
                                        </div>
                                        
                                    </>
                                    }
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>

                    <div className="bottom-logos">
                    <a target="_blank" rel="noopener noreferrer" href={'https://urbanms.hu/'}>
                        <UmsLogo className="ums-logo" />
                        <UtuLogo className="utu-logo" />
                    </a>
                    </div>
                </div>
            </div>
            <Modal size="lg" className="other-info-modal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <div className="logo-container"><UtuLogo className="utu-logo" /></div>
                    <Modal.Title>Üdvözöljük az UTU manageyourcity felületén.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Az <strong>UTU</strong> <span>|urban interface|</span>© multifunkcionális és személyre szabható városüzemeltetési keretrendszer. Feladata, hogy összegyűjtse, tárolja és megjelenítse mindazon adatokat, amik városa üzemeltetése során keletkeznek. A rendszerben keletkező adatokból információt állít elő az önkormányzati folyamatok résztvevőiek és a döntéshozóknak, segítve a legoptimálisabb megoldások megtalálását a felmerülő problémákra.</p> <p>A belépést követően megkezdheti a rendszer használatát.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>vissza</Button>
                </Modal.Footer>             
            </Modal>

            {/* Modal for recovering PW input */}
            <Modal size="md" show={recoverPasswordShow} onHide={recoverPasswordShowHandleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Jelszóváltoztatás</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form.Group controlId="useremail">
                    <Form.Label>Felhasználónév</Form.Label>
                    <Form.Control type="email" placeholder="Adja meg a felhasználónevét" onChange={evt => setUseremail(evt.target.value)}/>
                </Form.Group>
                <p>{message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={recoverPasswordShowHandleClose}>Bezárás</Button>
                    <Button variant="primary" onClick={recoverPassword}>Jelszóváltoztatás igénylése</Button>
                </Modal.Footer>
            </Modal>
            </React.Fragment>

        );
};

Login.propTypes = {};

export default Login;
