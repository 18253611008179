import Config from "../config/config";

export class OwletController {

    private static instance: OwletController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): OwletController {
        if (!OwletController.instance) {
            OwletController.instance = new OwletController();
        }
        return OwletController.instance;
    }

    public authentication() {
    }

    public getOwletStatistics(from:any, to:any) {
    }

    public getBrokenLamps(from:any, to:any) {
    }

    public getLostNodes(from:any, to:any) {
    }

    public getHighPower(from:any, to:any) {
    }


    public getLamp(deviceId:any) {
    }
    
};
