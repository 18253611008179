//Import interfaces
import {
    filterObjectInterface
} from "../ts/interfaces/kataszterconroller_interfaces"
import Config from "../config/config";


export class KataszterController {

    private static instance: KataszterController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {
    }

    /**
     * Controlling the singleton
     */
    public static getInstance(): KataszterController {
        if (!KataszterController.instance) {
            KataszterController.instance = new KataszterController();
        }
        return KataszterController.instance;
    }


    /**
     * Send message to the backend with parameters: 
     * @param projectId string - id of the project to update
     * @param timeStamp Integer - timestamp of the json created, every newer task data should be updated
     */
    public getKatasztersById(id:string, page:string, limit:string, filters:Array<filterObjectInterface>) {
        const url = Config.endpoints.base + Config.endpoints.list.kataszter.get;
        let urlParams:string = "/?identity=" + sessionStorage.username + "&limit=" + limit + "&page=" + page

        if(Object.keys(filters).length) {
            Object.entries(filters).forEach(([key, value]) => {
                urlParams = urlParams + "&" + key + "=" + value;
            })
        }

        return new Promise((resolve, reject) => {
            fetch(url + urlParams, {
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        })
    }

    /**
     * Send message to the backend with parameters: 
     * @param projectId string - id of the project to update
     * @param timeStamp string - timestamp of the json created, every newer task data should be updated
     * @param updateData object - values to be updated, example : {datee:, dateo}
     */
    public updateKataszterById(id:string, data:any, coord:any) {

        const url = Config.endpoints.base + Config.endpoints.list.kataszter.update;
        let formData = new FormData();

        formData.append("id", id);
        formData.append("identity", sessionStorage.username);

        Object.keys(data).forEach((key:any) => {
            formData.append("data["+ key +"]", data[key]);
        });

        formData.append("geometry[0][]", coord[0]);
        formData.append("geometry[0][]", coord[1]);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method:"POST",
                body:formData,
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        })
    }

    /**
     * getAllCadastre
     */
    public getAllCadastre() {
        const url = Config.endpoints.base + Config.endpoints.list.kataszter.list;

        return new Promise((resolve, reject) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                response.forEach((cadastre:any) => {
                    if(Config.layers.hasOwnProperty(cadastre.cadastre_ref)) {
                        Config.layers[cadastre.cadastre_ref].title = cadastre.cadastre_label;
                        Config.layers[cadastre.cadastre_ref].icon = cadastre.icon;

                        if(Config.layers[cadastre.cadastre_ref].hasOwnProperty("marker")) {                            
                            Config.layers[cadastre.cadastre_ref].marker.src = "markers/" + cadastre.cadastre_ref + ".png";
                            Config.layers[cadastre.cadastre_ref].marker_highlight.src = "markers/" + cadastre.cadastre_ref + ".png";
    
                        }
                    }
                });
                this.saveCadastre(response);
                resolve({statusCode:200, data:response})
            })
        })
    };

    private saveCadastre(data:any) {
        //Get cadastre data while login
        let temp:any = {};
        data.forEach((cadastre:any) => {
            temp[cadastre.cadastre_id] = cadastre;
        });

        sessionStorage.setItem("cadastreData", JSON.stringify(temp));
    }

    /**
     * getAllCadastreGroups
     */
    public getAllCadastreGroups() {
        const url = Config.endpoints.base + Config.endpoints.list.kataszter.groups;

        return new Promise((resolve, reject) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        })

    }
    /**
     * getNumberOfCadastres
     */
    public getNumberOfCadastres(){
        const url = Config.endpoints.base + Config.endpoints.list.kataszter.numberofcadastres;

        return new Promise((resolve, reject) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        })
    }
    /**
     * getNumberOfItemsInCadastres
     */
    public getNumberOfItemsInCadastres(){
        const url = Config.endpoints.base + Config.endpoints.list.kataszter.numberofitemsincadastres;
        
        return new Promise((resolve, reject) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        })
    }

    public getCadastreSpots(ids:any, page:any, limit:any, filters:any, geojson:any, sort:any) {
        const url = Config.endpoints.base + Config.endpoints.list.kataszter.cadastrespots;
        let formData = new FormData();

        ids.forEach((id:any) => {
            formData.append("filters[id][]", id);
        });

        if(geojson) {
            formData.append("filters[geojson]", '1')
        }

        if(page && limit) {
            formData.append("filters[page]", page);
            formData.append("filters[limit]", limit);
        }
        if(filters.length) {
            filters.forEach((element:any) => {
                formData.append("filters[" + element.type + "][" + element.id + "][" + element.name +"]", element.value)
            });
        };

        if("name" in sort) {
            formData.append("sort[" + sort.name + "]", sort.desc ? "desc" : "asc");
        }
    
        return new Promise((resolve, reject) => {
            fetch(url, {
                method:"POST",
                body:formData
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            }).catch(err => {
                reject({statusCode:500, data:'Failed to fetch'})
            })
        });

    }

    public uploadFile(id:any, files:any, desc:any) {
        const url = Config.endpoints.base + Config.endpoints.list.kataszter.file.upload;
        let formData = new FormData();

        formData.append("cadastreItemId", id);
        formData.append("userId", sessionStorage.user_id);


        files.forEach((file:any, index:any) => {
            formData.append("files["+ index +"]", file, file.name);
            formData.append("desc["+ index +"]", desc[file.name]);
        });

        return new Promise((resolve, reject) => {
            fetch(url, {
                method:"POST",
                body:formData,
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response});
            })
        });
    };

    public deleteFile(id:any) {
        const url = Config.endpoints.base + Config.endpoints.list.kataszter.file.delete;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("userId", sessionStorage.user_id);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method:"POST",
                body:formData,
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:{success:true}});
            })
        });
    };


    public getLiveData(ref:any) {
        const url = Config.endpoints.base + Config.endpoints.list.kataszter.live.get.replace("{ref}", ref);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        });
    }

};
