import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { history } from '../../state/store';
import renderHTML from 'react-render-html';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DashboardBox(props) {

    function navigateToLink() {
        if(props.route) {
            if(props.route.includes('http')) {
                window.open(props.route, '_blank');
            }else {
                history.push(props.route)
            }
        }
    }
    
    return(
        <Container className="dashboard-card-body" style={{cursor: 'pointer'}} onClick={props.type !== "new" ? navigateToLink : undefined} >
            <div className="dashboard-card-inside">
                <Row className="dashboard-card-header">
                    {props.active && 
                    <Col lg="12">
                        <i role="button" className="dashboard-card-button" onClick={() => { navigateToLink() }}>Megnyitás</i>
                    </Col>}
                    <Col lg="12" className="dashboard-header-col">
                        {props.icon && 
                        <div className={'icon icon-'+ props.class }>
                            <FontAwesomeIcon size="2x" icon={['fas', props.icon]} />
                        </div>}
                        <p className="dashboard-card-title" style={{color: props.active ? "black" : "#ababab"}}>{props.title}</p>
                        <p className="dashboard-card-desc">{props.children} {renderHTML(props.body)}</p>
                    </Col>
                </Row>
                <div className="dashboard-card-footer">
                    {props.smart ?
                    <>
                        <Row className="dashboard-card-footer-details">
                            {props.smart.data.map(data => {
                                return(
                                    <Col xs={12/props.smart.data.length}>{data}</Col>
                                );
                            })}

                        </Row>
                        <Row className="dashboard-card-footer-header">
                            {props.smart.title.map(title => {
                                return(<Col xs={12/props.smart.data.length} >{title}</Col>);
                            })}
                            <Col>utolsó módositás</Col>
                        </Row>
                    </>
                    :
                    null
                    }
                </div>
            </div>
        </Container>
    )
}